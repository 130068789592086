<template>
  <div
    ref="splitHero"
    v-editable="blok"
    class="split-hero"
    :class="animate && 'split-hero--animate'"
  >
    <div class="split-hero__content">
      <CustomRTE :blok="blok.text[0]" />
      <div
        v-if="
          (blok.ctas && blok.ctas.length > 0) ||
          (blok.zip_search && blok.zip_search.length === 1)
        "
        class="split-hero__ctas"
      >
        <CTAGroup
          v-if="
            blok.ctas &&
            blok.ctas.length > 0 &&
            (!blok.zip_search || blok.zip_search.length === 0)
          "
          :ctas="blok.ctas"
        />
        <ZipSearch
          v-else-if="
            blok.ctas &&
            blok.ctas.length === 0 &&
            blok.zip_search &&
            blok.zip_search.length === 1
          "
          :blok="blok.zip_search"
        />
        <RichTextEditor
          v-if="blok.legalText"
          class="mt-4"
          :blok="{ text: blok.legalText }"
        />
      </div>
    </div>
    <div class="split-hero__media">
      <Picture
        v-editable="blok"
        :blok="makeLayeredPicture()"
        :thumbnail="blok.image_layered"
        loading="eager"
        position="left"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SplitHeroBlokProps } from '@/types';

// Plugins
const { $noMotionPreference } = useNuxtApp();

// Props
const props = defineProps<{
  blok: SplitHeroBlokProps;
}>();

const makeLayeredPicture = () => ({
  image: props.blok.image_default,
});

// Variables & refs
let observer: IntersectionObserver;
const animate = ref(false);
const splitHero = ref<HTMLDivElement>();

// Lifecycle : onMounted
onMounted(() => {
  // Only set up IO if no motion preference and there's an item to animate
  if ($noMotionPreference === true && splitHero.value) {
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          animate.value = true;
        }
      });
    });

    observer.observe(splitHero.value);
  }
});
</script>

<style lang="scss">
.split-hero {
  @include container;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  justify-content: space-between;

  @media all and #{$mq-m-min} {
    flex-direction: row;
    gap: columns(1);
  }

  &__content {
    @media all and #{$mq-m-min} {
      margin-block-start: var(--spacing-s);
      max-width: columns(10);
    }

    .cta-group,
    .zip-search {
      margin-block-start: var(--spacing-s);
    }
  }

  &__ctas {
    @media all and #{$mq-m-min} {
      max-width: columns(7);
    }
  }

  .custom-rte__text {
    @media all and #{$mq-m-min} {
      max-width: columns(7);
    }
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .split-hero {
    opacity: 0;
  }

  .split-hero--animate {
    --timing-text-cta-fade-delay: calc(
      var(--timing-heading-wipe) + var(--timing-hero-start-delay)
    );
    --timing-large-image-delay: calc(
      var(--timing-text-cta-fade-delay) + var(--timing-text-cta-fade)
    );
    --timing-small-image-delay: calc(
      var(--timing-large-image-delay) + var(--timing-large-image-fade)
    );

    opacity: 1;
    transition: opacity 0ms linear var(--timing-hero-start-delay);

    .eyebrow {
      animation: fade-in var(--timing-eyebrow) var(--easing-3) forwards;
      animation-delay: var(--timing-hero-start-delay);
      will-change: opacity;
    }

    .custom-heading {
      animation: wipe-left var(--timing-heading-wipe) var(--easing-2),
        scale var(--timing-heading-scale) var(--easing-2),
        fade-in var(--timing-heading-fade) var(--easing-3);
      animation-delay: var(--timing-hero-start-delay);
      animation-fill-mode: forwards;
      will-change: clip-path, transform, opacity;
    }

    .custom-rte__text,
    .split-hero__ctas {
      animation: fade-in var(--timing-text-cta-fade) var(--easing-3)
        var(--timing-text-cta-fade-delay) forwards;
      opacity: 0;
      will-change: opacity;
    }

    .picture {
      &__image {
        animation: wipe-right var(--timing-large-image-wipe) var(--easing-2)
          var(--timing-large-image-delay) forwards;
        display: block;
        will-change: clip-path;

        > img {
          animation: scale var(--timing-large-image-scale) var(--easing-2),
            fade-in var(--timing-large-image-fade) var(--easing-3);
          animation-delay: var(--timing-large-image-delay);
          animation-fill-mode: forwards;
          opacity: 0;
          transform: scale(1.1);
          will-change: transform, opacity, clip-path;
        }
      }

      &__thumbnail {
        animation: wipe-left var(--timing-small-image-wipe) var(--easing-2),
          fade-in var(--timing-small-image-fade) var(--easing-3);
        animation-delay: var(--timing-small-image-delay);
        animation-fill-mode: forwards;
        opacity: 0;
        will-change: clip-path, opacity;
      }
    }
  }
}
</style>
